<template>
    <div class="article_card">
        <div class="content_container">
            <span class="date-card">{{ date }}</span>
            <span :title="title" class="title-card">{{ title }}</span>
            <p>
                {{ description }}
                <!--<router-link :to="`/articles/${id}`"> {{ $t("Read more!") }}</router-link>-->
            </p>
            <div class="details-container">
                <span class="topics">{{ category }}</span>
                <span class="author"> By {{ author }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" fill="none">
                    <circle cx="5.17578" cy="4.21875" r="4.21875" fill="#797979" />
                </svg>
                <span class="date-card"> {{ time }} min read <font-awesome-icon icon="fa-regular fa-clock" /></span>
            </div>
        </div>
        <div class="image_container">
            <div class="cover_img">
                <router-link :to="`/blog/${id}`"> {{ $t("Read more!") }}</router-link>
            </div>
            <img loading="lazy" decoding="async" :src="getImgUrl(image)" width="500" height="250" />
        </div>
    </div>
</template>

<script>
import truncate from "@/helpers/truncateText";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
    name: "articleCard",
    props: ["image", "title", "time", "description", "text", "date", "category", "author", "id"],
    components: { FontAwesomeIcon },
    computed: {
        preview() {
            return truncate(this.text, 300);
        },
    },
    methods: {
        getImgUrl(imgFileId) {
            if (imgFileId) {
                var image = `data:image/png;base64,${imgFileId}`;
                // console.log({ image });
                return image;
            }
            return require("../assets/Images/user.png");
        },
    },
};
</script>

<style lang="scss" scoped>
.article_card {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 190px;
    width: 100%;
    text-align: start;

    // flex: 0 0 30%;
    transition: all 0.4s;
    background: #fff;
    border-radius: 5px;
    //   @media screen and (max-width: 950px) {
    //     flex: 0 0 45%;
    //   }

    a {
        color: #fff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &:hover {
        /* box-shadow:
            0px 3px 4px 0px rgba($color: #000000, $alpha: 0.3),
            0 -3px 4px 0px rgba($color: #000000, $alpha: 0.3);*/

        div > .cover_img {
            width: 100%;
            opacity: 1;
        }
    }

    .image_container {
        position: relative;
        width: 40%;

        img {
            // border-radius: 5px 0 0 5px;
            object-fit: fill;
            margin: auto;
            width: 100%;
            height: 100%;
        }

        .cover_img {
            width: 0%;
            position: absolute;
            height: 100%;
            z-index: 1;
            opacity: 0;
            background: rgba($color: #000, $alpha: 0.5);
            transition: all ease-in-out 0.6s;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .content_container {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        width: 60%;

        p {
            color: #7d7d7d;
            font-family: Roboto;
            font-size: 13.5px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 4;
            /* number of lines to show before adding ellipsis */
            -webkit-box-orient: vertical;

            a {
                color: inherit;
            }
        }
    }

    .title-card {
        color: #1e1e1e;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        /* number of lines to show before adding ellipsis */
        -webkit-box-orient: vertical;
    }

    .date-card {
        color: #00aef0;
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
    }

    .details-container {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 5px;
    }

    .topics {
        border-radius: 30px;
        border: 1px solid #c9c9c9;
        background: #fff;
        color: #797979;
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        padding: 3px 7px;
        margin-right: 6px;
    }

    .author {
        color: #797979;
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
    }
}
</style>
